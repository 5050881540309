@import 'design/template/mixin_templates';

/*-----------------------------
    GÉNÉRAL
-----------------------------*/

*, html {
    outline: 0;
    margin: 0;
    padding: 0;
}

body {
    line-height: 1.2;
    list-style-position: inside;
    font: .75em verdana, arial, sans-serif;
    background: #FFF;
}

iframe{
    max-width: 100%;
}

@include titreBaseSize();
h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
    margin: 0;
    padding: 0;
}

h1, .h1 {
    font-size: 3em;
}

h2, .h2 {
    font: bold 1.4em arial, sans-serif;
    text-transform: uppercase;
    letter-spacing: .15em;
    margin: 0 0 10px;
    padding: 15px 0 0;
}

h3, .h3 {
    font-family: arial, sans-serif;
}

h4, .h4 {
    margin-bottom: 10px;
}

a img {
    border: none;
}

/*-----------------------------
    HEADER
-----------------------------*/

#header {
    width: 100%;
    margin: 0;
}

#headercontent {
    max-width: 960px;
    height: 140px;
    position: relative;
    margin: auto;
    padding: 20px;
    box-sizing: border-box;
}

#headercontent a {
    text-decoration: none;
    font-family: arial, sans-serif;
}

.logo {
    display: inline-block;
    img {
        max-width: 100%;
        max-height: 100px;
    }
}

.slogan {
    width: 400px;
    font-size: 1.8em;
    text-align: right;
    font-family: arial, sans-serif;
    position: absolute;
    bottom: 20px;
    right: 20px;
    min-height: 20px;
}

/*-----------------------------
    MENU
-----------------------------*/

#header nav#nav-principal {
    margin: auto;
}

#header nav#nav-principal ul {
    width: 100%;
    text-align: center;
    margin: auto;
    padding: 5px 0;
}

#header nav#nav-principal li {
    list-style-type: none;
    display: inline-block;
    text-align: center;
    overflow: hidden;
    font-weight: 700;
    margin-right: 10px;
}

#header nav#nav-principal a {
    text-transform: uppercase;
    font-family: Arial, sans-serif;
    text-decoration: none;
    display: block;
    -webkit-transition-property: color;
    -webkit-transition-duration: .4s;
    -moz-transition-property: color;
    -moz-transition-duration: .4s;
    transition-property: color;
    transition-duration: .4s;
    padding: 10px;
}

#header nav#nav-principal p {
    padding: 0 5px;
}

#header nav#nav-principal p.subtext {
    padding-top: 35px;
    font-family: verdana, arial, sans-serif;
    font-size: .9em;
}

nav#nav-principal ul > li > ul {
    position: absolute;
    left: 0;
    background: #000 !important;
    width: 100%;
    z-index: 9999;
}

div#menu {
    -webkit-box-shadow: 0 0 5px #000;
    -moz-box-shadow: 0 0 5px #000;
    box-shadow: 0 0 5px #000;
}

div#menu ul {
    list-style-type: none;
    width: 100%;
    text-align: center;
    margin: auto;
}

div#menu ul li {
    text-transform: uppercase;
    display: inline-block;
    margin-bottom: 5px;
    margin-top: 5px;
    cursor: pointer;
    padding: 10px;
}

div#menu ul li a {
    text-decoration: none;
    font-family: "Trebuchet MS", sans-serif;
}

#menu > ul > li > ul {
    position: absolute;
    left: 0;
    width: 100%;
    margin-top: -5px;
    display: none;
}

nav#nav-principal > ul > ul > li > ul {
    position: absolute;
    display: none;
    width: 30% !important;
    text-align: left !important;
    margin-top: -10px !important;
    padding: 0 !important;
}

nav#nav-principal > ul > ul > li > ul > li a {
    padding: 0 0 0 10px !important;
}

nav#nav-principal ul li ul li:hover a {
    color: #fff;
}

div#menu ul ul ul {
    display: none;
}

#nav-static-principal > ul > li {
    > a {
        display: none !important;
    }
}

/*-----------------------------
    CONTENU
-----------------------------*/

#wrapper {
    background: #fff;
    width: 960px;
    margin: 0 auto 30px;
}

#contentpage_center .produits {
    border-width: 9px;
}

#content ol, #content ul {
    margin-left: 10px;
    list-style-type: circle;
    list-style-position: inside;
}

#content {
    min-height: 500px;
    margin: 0 10px 10px;
    padding: 0 0 20px;
}

#content_top {
    overflow: hidden;
}

#contentfull {
    padding-top: 0;
    margin-top: 30px;
    margin-bottom: 30px;
}

#contentpage p {
    font-size: 1.1em;
}

/*-----------------------------
    PRODUITS
-----------------------------*/

body #fiche-produit .remise-produit {
    height: 38px;
    padding: 14px 0 5px 12px;
    width: 50px;
}

.produits {
    position: relative;
    float: left;
    width: 23%;
    text-align: center;
    margin: 10px 1%;
    padding: 10px;
    box-sizing: border-box;
}

.produits img {
    margin: 0 !important;
    max-width: 100%;
    box-sizing: border-box;
    height: auto;
}

.produits .container-etiquette{
    position: absolute;
    padding: 0 12px;
    width: 100%;
    left: 0;
    top: 0;
    box-sizing: border-box;
    .produit_etiquette{
        position: relative;
        width: 100%;
        left: 0;
        top:2px;
    }
}

.produits p {
    margin: 5px 0 !important;
}

.produits p.nomprod {
    height: 30px;
    font: italic bold 1.1em Georgia, sans-serif;
}

.produits p.titreprod {
    font-size: .9em !important;
    line-height: 1em !important;
}

.produits p.titreprod:hover {
    height: 30px;
    font: italic bold 1.1em Georgia;
}

.produits p.attr {
    height: auto;
    font-weight: 700;
    font-size: .85em;
}

.produits .prixprod {
    margin: 5px 0;
}

.produits .oldprix {
    font-size: .9em;
    text-decoration: line-through;
    color: red;
}

.produits select {
    width: 100%;
}

.produits a.addbasket {
    cursor: pointer;
    display: inline-block;
    margin-left: 8px;
    padding: 5px 5px 5px 20px;
}

.produits .remise {
    right: 18px;
    top: 87px;
    z-index: 100;
}

.remise {
    position: absolute;
    width: 56px;
    height: 56px;
    font-size: 10px;
    top: 30px;
    padding-top: 15px;
    right: -15px;
    display: block;
}

.produits .produit_etiquette {
    z-index: 100;
    width: 90%;
    height: 26px;
    left: 5%;
}

.produit_etiquette:after {
    margin-left: -9px;
    border-width: 9px;
}

.produit_etiquette2 {
    bottom: 0;
}

.produit_etiquette h3, .produit_etiquette2 h3, .produit_etiquette3 h3,
.produit_etiquette .h3, .produit_etiquette2 .h3, .produit_etiquette3 .h3 {
    padding-top: 5px;
    font-size: 13px;
}

#boutique .is_etiquette .remise {
    top: 170px;
    border-bottom-right-radius: 0;
    border-top-right-radius: 20px;
}

.prixprod {
    font-weight: 700;
    font-size: 1.1em !important;
}

.nomprod {
    font-weight: 700;
    font-size: .9em !important;
    line-height: 1em !important;
}

.ajouter-panier .addbasket {
    cursor: pointer;
    display: block;
    background: url(../images/panier_add.png) no-repeat;
    margin-left: 8px;
    padding: 5px 5px 5px 20px;
    border: none;
}

.button, #paiementchoix .button {
    font-size: 1em;
    clear: both;
    font-weight: 700;
    border: 1px solid #ccc;
    width: auto;
    text-decoration: none;
    margin-bottom: 20px;
    cursor: pointer;
    -webkit-transition-property: background-color;
    -webkit-transition-duration: 1s;
    -moz-transition-property: background-color;
    -moz-transition-duration: 1s;
    transition-property: background-color;
    transition-duration: 1s;
    padding: 10px;
    display: inline-block;
}

.produits p, .galerie p {
    text-align: center;
    font-size: .85em;
}


#fiche-produit .top_fiche .reservation .reservation__addbasket .block-addbasket {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    > .button {
        flex: 0 1 auto;
        margin: 15px 0;
        display: inline-block;
        .fa {
            margin-right: 10px;
        }
        &.addbasket {
            padding: 10px 10px 10px 30px;
            background-position: 8px 4px;
        }
    }

    .addbasket .fa {
        display: none;
    }

    > .button + .button {
        margin-left: 15px;
    }

}

.modal {
    z-index: 2041;
}

.modal-backdrop {
    z-index: 2040;
}

/*-----------------------------
    GALERIE / SLIDER
-----------------------------*/

body #slider {
    margin: 20px auto;
}

#sliderContent, .sliderImage, .sliderImage span, .sliderImage li {
    width: 100% !important;
}

.sliderImage {
    text-align: center !important;
}

.sliderImage img {
    text-align: center;
    width: auto;
    max-width: 100% !important;
}

.galerie {
    float: left;
    text-align: center;
    padding: 10px;
}
.galerie_size {
    width: 19%;
}
.galerie_gutter {
    width: 1%;
}
.galerie{
    box-sizing: border-box;
    width: 19%;
    margin: 5px .5%;
}

.galerie img {
    border: 1px solid silver;
    margin: 10px 0;
    height: auto !important;
    max-width: 100% !important;
    width: 100% !important;
}

.diaporama {
    text-align: center;
    text-decoration: none;
    margin-top: 20px;
    padding-top: 20px;
}

.diaporama a {
    text-decoration: none;
}

.diaporama a:hover {
    text-decoration: underline;
}

/*-----------------------------
    FORMULAIRE
-----------------------------*/

#form1 {
    position: relative;
    padding-top: 10px;
    width: 938px;
}

#form1 fieldset {
    border: none;
    float: left;
    display: inline;
    width: 220px;
    margin: 0 0 0 20px;
    padding: 0;
}

#form1 p {
    font-weight: 700;
    margin: .5em 0;
}

#form1 label {
    display: block;
    font-size: 12px;
    font-family: Arial, Helvetica, sans-serif;
    margin: 3px;
}

#form1 input, #form1 textarea {
    width: 202px;
    margin: 3px;
    padding: 5px;
}

#form1 textarea {
    height: 125px;
    width: 640px;
    overflow: auto;
}

#form1 input.button {
    font-size: 1em;
    clear: both;
    font-weight: 700;
    display: block;
    width: 200px;
    text-decoration: none;
    margin-left: 660px;
    margin-bottom: 20px;
    cursor: pointer;
    -webkit-transition-property: background-color;
    -webkit-transition-duration: 1s;
    -moz-transition-property: background-color;
    -moz-transition-duration: 1s;
    transition-property: background-color;
    transition-duration: 1s;
    padding: 10px;
}

/*-----------------------------
    LIVRE D'OR
-----------------------------*/

.message_lo {
    position: relative;
    text-align: left;
    margin: 15px 0;
    padding: 15px;
}

.message_lo p {
    text-align: justify;
    margin-bottom: 5px;
}

.message_lo .message {
    font-style: italic;
    font-size: 1em;
    text-align: justify;
    margin-bottom: 5px;
}

.message_lo p.note {
    position: absolute;
    right: 15px;
    top: 15px;
    font-weight: 700;
}

.message_lo p.web {
    font-weight: 700;
    text-align: justify;
    font-size: .8em;
    margin: 10px 0 5px;
}

a.addmsglo {
    font-weight: 700;
    display: block;
    width: 250px;
    text-decoration: none;
    -webkit-transition-property: background-color;
    -webkit-transition-duration: 1s;
    -moz-transition-property: background-color;
    -moz-transition-duration: 1s;
    transition-property: background-color;
    transition-duration: 1s;
    padding: 10px;
}

/*-----------------------------
    AJOUT MESSAGE
-----------------------------*/

#addmsg input {
    margin-top: 8px;
    margin-bottom: 20px;
    padding: 5px;
}

#addmsg textarea {
    width: 640px;
    margin-bottom: 10px;
    padding: 5px;
}

#addmsgleft {
    float: left;
    margin-right: 50px;
}

#addmsg input[type=submit] {
    margin-left: 550px;
}

#addmsg a {
    text-decoration: none;
}

.texthautcontact {
    text-align: center;
    padding-top: 20px;
}

#contactright {
    float: right;
    margin: 40px 0 20px;
}

#contactleft {
    float: left;
    width: 300px;
    margin: 20px 0;
}

/*-----------------------------
    FOOTER
-----------------------------*/

#footer {
    text-align: center;
    padding: 20px;
    width: 100%;
    box-sizing: border-box;
}

#footer p {
    width: 100%;
    margin: 0 auto;
}

#fiche-produit{
    .wrap-description{
        h1, .h1{
            font-size: 24px;
        }
    }
}

/*-----------------------------
    AUTRE
-----------------------------*/

.clearer {
    clear: both;
}

#pub {
    background: #ccc;
    width: 728px;
    height: 90px;
    color: #000;
    margin: 0 auto 15px;
}

body #header, body #header nav#nav-principal, body #content_top, body #menu, body #wrapper #boutiqueaccueil, body #footer {
    display: block;
}

#wrapper #header, body nav#nav-principal, body #wrapper nav#nav-principal, body #wrapper #header nav#nav-principal, body #wrapper #slider, body #wrapper #menu, body #wrapper #contentpage_left #boutiqueaccueil, body #wrapper #contentpage_center #boutiqueaccueil, body #wrapper #contentpage_right #boutiqueaccueil, #wrapper #footer, #header nav#nav-principal ul li ul, #form1 legend {
    display: none;
}

#GoogleMapAffichage {
    color: #000 !important;
}

/********************* A GARDER POUR LES MODELS ********************/
/*Gérer les images dans les tableaux*/
.links_p {
    position: relative;
}

#contentpage img {
    max-width: 100% !important;
    height: auto !important;
}

#contentpage .zoomPupImgWrapper > img, #contentpage .zoomWrapperImage > img {
    max-width: none !important;
}

table {
    position: relative;
    table-layout: fixed;
}

td {
    max-width: 50%;
}

#contentpage td img {
    max-width: 100% !important;
}

#footer.footer_p .links_p td img, #footer.footer_p .links_p img {
    width: auto !important;
    max-width: 100% !important;
}

.links_p table {
    max-width: 100% !important;
}

/*Titre des commentaires du livre d'or ne prend pas toute la largeur*/
.message_lo h4,
.message_lo .h4 {
    width: 80%;
}

.container-comment, .content-cmonsite:not(.form-search) {
    overflow: hidden;
}

/*-----------------------------
    FLUX PANIER
-------------------------------*/

#flux-panier.template_flux_panier.template_flux_panier_1 {
    .side-tabs ul li.step-done a {
       color: #fff;
    }
}

/******************************************\
|************* MEDIA QUERIES **************|
\******************************************/

@media screen and (max-width: 960px) {
    #wrapper, #slider {
        width: 90% !important;
        box-sizing: border-box;
    }

    #footer img {
        max-width: 100%;
        height: auto;
    }
    .produits {
        width: 31%;
    }
    .galerie_size {
        width: 32%;
    }
    .galerie_gutter {
        width: 1%;
    }
    .galerie{
        width: 32%;
        margin: 5px .5%;
    }
    .search-bar {
        width: 95%;
    }
}

@media screen and (max-width: 768px) {
    #contentpage_center {
        overflow: hidden;
    }
    /*Suppression des blocs en dehors du contenu*/
    #block-flottant-search, #minisite-sidebar {
        display: none;
    }
    .bx-wrapper {
        height: auto;
        width: 80%;
        margin: 10px auto;
    }
    .bx-wrapper a.one_image {
        height: 150px;
    }

}

@media screen and (max-width: 680px) {
    #headercontent {
        height: auto;
    }
    .slogan {
        left: 0 !important;
        top: 0 !important;
        right: auto !important;
        bottom: auto !important;
        text-align: center !important;
        font-size: 18px !important;
        width: 100% !important;
        position: relative !important;
        padding-bottom: 15px !important;
        float: none !important;
        height: auto !important;
        z-index: 99 !important;
    }
    .logo {
        padding-bottom: 15px;
        position: static !important;
        display: block !important;
        margin: 0 auto !important;
        padding-top: 15px;
        height: auto !important;
        text-align: center;
        width: 100% !important;
        float: none !important;
        img {
            max-height: 70px !important;
            width: auto !important;
            max-width: 100% !important;
            height: auto !important;
            position: static !important;

        }
    }
    #wrapper nav#nav-principal li {
        height: 50px !important;
    }
    #content {
        margin-top: 0;
    }
    .search-bar {
        width: 90%;
    }
    /*Fiche produit*/
    #fiche-produit #formulaire .form-group {
        float: none !important;
    }
    #fiche-produit .input-form-petit {
        width: auto !important;
    }
    #fiche-produit .input-form-moyen {
        width: 100% !important;
    }
    #fiche-produit .wrap-images, #fiche-produit .wrap-description {
        width: 100% !important;
        float: none !important;
    }
    .bx-wrapper table {
        width: 100% !important;
    }
    #contentpage_right, div#wrapper #menu ul {
        float: none;
        width: 100%;
    }
    div#wrapper #menu ul nav {
        padding-top: 25px;
    }
}

@media screen and (max-width: 600px) {
    form .row .col.s3 {
        width: 25%;
    }
    .form-group textarea {
        width: 100% !important;
    }
    .produits {
        width: 48%;
    }
    .galerie_size {
        width: 49%;
    }
    .galerie_gutter {
        width: 1%;
    }
    .galerie{
        width: 49%;
        margin: 5px .5%;
    }
    .thumbnail {
        float: left;
        width: 42%;
        margin: 2.5%;
    }
    td {
        max-width: 100%;
    }
    @import 'design/helper/responsive/mobile/mobile';
    .ui-datepicker {
        width: 98% !important;
        font-size: .75em;
        left: 0 !important;
    }
    nav#nav-principal li {
        width: 50%;
    }

}

@media screen and (max-width: 500px) {
    .message_lo {
        margin: 15px 5px;
    }
    .search-bar {
        width: 85%;
    }
    #panier {
        right: 0 !important;
        width: 100% !important;
        box-sizing: border-box;
    }
    .ui-datepicker {
        left: 0 !important;
    }
    nav#nav-principal li {
        width: 100%;
    }
    .bx-wrapper {
        width: 70%;
    }
}

@media screen and (max-width: 400px) {
    #wrapper, #slider {
        width: 95% !important;
        box-sizing: border-box;
    }
    .produits {
        width: 75% !important;
        margin: 0 auto 12px !important;
        float: none;
    }
    .galerie_size {
        width: 99%;
    }
    .galerie_gutter {
        width: 1%;
    }
    .galerie{
        width: 99%;
        margin: 5px .5%;
    }
    .thumbnail {
        float: none;
        width: 75%;
        margin: 0 auto 12px;
        padding: 15px;
    }
    .content-payment-global {
        font-size: 15px !important;
    }
}

@media screen and (max-width: 360px) {
    #content .in {
        padding: 15px 8px;
    }
    ul.sous_navigation {
        margin: 15px 0 15px 10px;
    }
    .message_lo {
        margin: 15px 0;
    }
    #content form {
        overflow: hidden;
    }
    form .row .col {
        padding: 0 2px;
    }
    form .row .col.s3 {
        width: 50%;
    }
}

@media screen and (max-width: 320px) {

}

@media screen and (min-width: 680px) {
    nav#nav-principal li:hover > ul {
        display: block !important;
    }
    .menu_burger {
        display: none;
    }
}

@media screen and (max-width: 680px) {

    /* Nav mobile */
    #menu {
        display: none !important;
    }
    nav#nav-principal ul li {
        float: left;
        z-index: auto !important;
        position: relative;
        width: 100% !important;
        padding: 0;
    }
    nav#nav-principal.nav_fermee > ul {
        display: none;
    }
    .hasDropdown {
        display: block !important;
        opacity: 1 !important;
    }
    nav#nav-principal ul > li {
        border: none;
        width: 100%;
        display: block;
        margin: 0;
        position: relative;
        box-sizing: border-box;
    }
    #nav ul.menu li:hover a {
        color: #fff;
    }
    .menu_burger {
        display: block;
        cursor: pointer;
        font-size: 16px;
        font-weight: bold;
        padding: 10px 5px;
        width: 100%;
        position: relative;
        text-align: center;
        z-index: 9999;
        box-sizing: border-box;
    }
    .menu_burger::before {
        font-family: FontAwesome, sans-serif;
        content: "\f0c9";
        margin-right: 5px;
    }
    nav#nav-principal > ul {
        width: 100%;
        position: relative;
        z-index: 9999;
        box-sizing: border-box;
        border: none;
        margin: 0;
    }
    nav#nav-principal.nav_ouverte > ul {
        display: block;
    }
    #header nav#nav-principal li {
        float: none;
        margin-right: 0;
        display: block;
        position: relative;
        overflow: visible;
        box-sizing: border-box;
    }
    nav#nav-principal li a {
        padding: 8px 8px;
        display: block;
        height: auto;
        float: none;
        margin: 0;
    }
    nav#nav-principal ul li a span {
        font-size: 17px;
    }
    .hasDropdown ul + span::after {
        font-family: FontAwesome, sans-serif;
        content: "\f0d7";
    }
    .dropDown {
        position: absolute;
        right: 2%;
        top: 2px;
        font-weight: bold;
        display: block;
        height: 25px;
        width: 8%;
        cursor: pointer;
        text-align: center;
        font-size: 1.2rem;
        padding-top: 5px;
        background-color: transparent !important;
    }
    nav#nav-principal > ul > li ul {
        display: block;
        position: relative;
        text-align: left;
        border: none;
        opacity: 1;
        height: auto !important;
        width: 100% !important;
        visibility: visible !important;
        background: lighten(#333, 20%);
    }
    nav#nav-principal li ul li a {
        padding: 5px;
        color: #fff !important;
    }
    nav#nav-principal li ul li {
        border-bottom: none;
    }
    nav#nav-principal > ul > li > a.current, nav#nav-principal li a:hover {
        background: transparent;
    }
    nav#nav-principal li a {
        display: inline-block;
        text-align: center;
        box-sizing: border-box;
        width: 100% !important;
        padding: 10px 0;
    }
    #pages {
        display: block;
        width: 90% !important;
        position: absolute;
        right: 0;
        top: 40px;
    }
    nav#nav-principal li {
        width: 95% !important;
        border: none !important;
    }
    .sousmenu_ouvert {
        z-index: 99999 !important;
    }
    nav#nav-principal ul li ul {
        position: relative;
        z-index: 999;
        left: 0;
        width: 100%;
        padding: 2px;
        box-sizing: border-box;
    }
    nav#nav-principal ul li.sousmenu_ouvert > ul {
        display: block;
    }
    nav#nav-principal ul li.sousmenu_ouvert > ul li a {
        float: left;
        margin: 5px;
        color: #fff;
    }
    nav#nav-principal ul li.sousmenu_ouvert > ul li a:hover {
        text-decoration: none;
        color: #fff;
    }
    #header nav#nav-principal a {
        margin: 0 !important;
        float: none !important;
        box-sizing: border-box;
    }
    /* FIN Nav mobile */

}

.nav-static-breadcrumb {
    margin-top: 5px;
    margin-bottom: 10px;
}

.nav-static-breadcrumb li:not(:last-child)::after {
    content: ' > ';
}

#fiche-produit .wrap-description .ajouter-panier .quantite{
    margin-right: 0;
}
.wrap-description .bloc-quantite .bloc-bt-quantity .bt-quantity{
    height: auto;
}